import React from 'react';
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  useColorMode,
  useColorModeValue,
  Icon,
  HStack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { FaCheckCircle } from 'react-icons/fa';
import { motion } from 'framer-motion';

function ThankYou() {
  const { toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const formBackground = useColorModeValue('white', 'gray.700');

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Box minHeight="100vh" display="flex" flexDirection="column">
      <Box bg={useColorModeValue('gray.100', 'gray.900')} py={4}>
        <Container maxW="container.lg" display="flex" justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="lg">
            Expo Vendor Registration
          </Heading>
          <Button onClick={toggleColorMode}>
            {useColorModeValue(<MoonIcon />, <SunIcon />)}
          </Button>
        </Container>
      </Box>
      <Container flex="1" maxW="container.lg" py={32}>
        <VStack
          as={motion.div}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          spacing={8}
          p={6}
          boxShadow="md"
          bg={formBackground}
          borderRadius="md"
        >
          <Icon as={FaCheckCircle} w={12} h={12} color="green.500" />
          <Heading as={motion.h2} size="xl" textAlign="center" initial={{ scale: 0.9 }} animate={{ scale: 1 }}>
            Thank You for Your Registration!
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Your Expo Vendor Registration has been submitted successfully. You will receive an email shortly, and we
            will reach out as soon as possible.
          </Text>
          <Button
            as={motion.button}
            whileHover={{ scale: 1.05 }}
            onClick={handleBackToHome}
            colorScheme="blue"
            size="lg"
          >
            Back to Home
          </Button>
        </VStack>
      </Container>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} py={4} mt="auto">
        <Container maxW="container.lg" textAlign="center">
          <Text>&copy; 2024 Expo Vendor Registration. All rights reserved.</Text>
        </Container>
      </Box>
    </Box>
  );
}

export default ThankYou;
