import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
  Textarea,
  Checkbox,
  Select,
  Grid,
  GridItem,
  Divider,
  Icon,
  useColorMode,
  useColorModeValue,
  VStack,
  HStack,
  Text,
  Flex,
} from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';
import { FaRegHandshake, FaLaptopCode, FaUsers } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { color } from 'framer-motion';

function FormPage() {
  const { toggleColorMode } = useColorMode();
  const formBackground = useColorModeValue('white', 'gray.700');
  const navigate = useNavigate();
  const [authToken, setAuthToken] = useState('');

  // State variables for checkboxes
  const [sellingProducts, setSellingProducts] = useState(false);
  const [assistanceWithSetup, setAssistanceWithSetup] = useState(false);
  const [additionalFurniture, setAdditionalFurniture] = useState(false);
  const [largeDisplays, setLargeDisplays] = useState(false);
  const [promotionalOpportunities, setPromotionalOpportunities] = useState(false);
  const [attendeeBagMaterials, setAttendeeBagMaterials] = useState(false);
  const [agreement, setAgreement] = useState(false);

  useEffect(() => {
    const authenticate = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_PB_URL}/api/admins/auth-with-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            identity: process.env.REACT_APP_PB_ADMIN_EMAIL,
            password: process.env.REACT_APP_PB_ADMIN_PASSWORD,
          }),
        });

        if (!response.ok) {
          throw new Error('Authentication failed');
        }

        const data = await response.json();
        setAuthToken(data.token);
      } catch (error) {
        console.error('Error during authentication:', error);
      }
    };

    authenticate();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;
    const formData = new FormData(form);
    const data = Object.fromEntries(formData.entries());

    // Convert checkbox values from strings to booleans
    const checkboxes = [
      'selling_products',
      'assistance_with_setup',
      'additional_furniture',
      'large_displays',
      'promotional_opportunities',
      'attendee_bag_materials',
      'agreement',
    ];

    checkboxes.forEach((checkbox) => {
      data[checkbox] = formData.get(checkbox) === 'true';
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_PB_URL}/api/collections/vendor_questionnaire/records`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (!response.ok) {
        throw new Error('Failed to submit data');
      }
      navigate('/thank-you');
    } catch (error) {
      alert('Error submitting data: ' + error.message);
    }
  };

  return (
    <Box>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} py={4}>
        <Container maxW="container.lg" display="flex" justifyContent="space-between" alignItems="center">
          <Heading as="h1" size="lg">
            Outdoor Expo Vendor Registration
          </Heading>
          <Button onClick={toggleColorMode}>
            {useColorModeValue(<MoonIcon />, <SunIcon />)}
          </Button>
        </Container>
      </Box>
      <Container maxW="container.lg" py={12}>
        <VStack spacing={8}>
          <Heading as="h2" size="xl" textAlign="center">
            Welcome to Our Outdoor Expo Vendor Registration
          </Heading>
          <Text fontSize="lg" textAlign="center">
            Join us for an exciting event where you can showcase your products and services to a wide audience.
          </Text>

          <Box w="full" px={[4, 8]}>
            <Flex direction={['column', 'row']} spacing={8} justify="center" align="center">
              <VStack align="center" textAlign="center" m={4}>
                <Icon as={FaRegHandshake} w={12} h={12} />
                <Text>Networking Opportunities</Text>
              </VStack>
              <VStack align="center" textAlign="center" m={4}>
                <Icon as={FaLaptopCode} w={12} h={12} />
                <Text>Product Demos</Text>
              </VStack>
              <VStack align="center" textAlign="center" m={4}>
                <Icon as={FaUsers} w={12} h={12} />
                <Text>Community Engagement</Text>
              </VStack>
            </Flex>
          </Box>

          <Box as="form" onSubmit={handleSubmit} p={6} boxShadow="md" bg={formBackground} borderRadius="md" w="full">
            <Stack spacing={6}>
              {/* Company Information */}
              <Heading as="h3" size="lg" mb={4}>
                Company Information
              </Heading>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Company Name</FormLabel>
                    <Input name="company_name" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Contact Person</FormLabel>
                    <Input name="contact_person" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Phone Number</FormLabel>
                    <Input name="phone_number" type="tel" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Email Address</FormLabel>
                    <Input name="email_address" type="email" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Website</FormLabel>
                    <Input name="website" type="url" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Company Address</FormLabel>
                    <Input name="company_address" />
                  </FormControl>
                </GridItem>
              </Grid>

              <Divider my={6} />

              {/* Booth Details */}
              <Heading as="h3" size="lg" mb={4}>
                Booth Details
              </Heading>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl isRequired>
                    <FormLabel>Booth Size Required</FormLabel>
                    <Select name="booth_size_required">
                      <option value="10x10">10x10 ft - $350</option>
                      <option value="10x20">10x20 ft - $450</option>
                      <option value="20x20">20x20 ft - $600</option>
                      <option value="40x40">40x40 ft - $1,000</option>
                      <option value="Other">Other</option>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Other Booth Size</FormLabel>
                    <Input name="other_booth_size" />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl isRequired>
                    <FormLabel>Number of Booths Required</FormLabel>
                    <Input name="number_of_booths_required" type="number" />
                  </FormControl>
                </GridItem>
              </Grid>

              <Divider my={6} />

              {/* Product/Service Information */}
              <Heading as="h3" size="lg" mb={4}>
                Product/Service Information
              </Heading>
              <FormControl isRequired>
                <FormLabel>Description of Products/Services to be Displayed</FormLabel>
                <Textarea name="products_services_description" />
              </FormControl>
              <FormControl>
                <FormLabel>Special Requirements</FormLabel>
                <Textarea name="special_requirements" />
              </FormControl>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Will you be selling products at the event?</FormLabel>
                    <Checkbox
                      name="selling_products"
                      value="true"
                      isChecked={sellingProducts}
                      onChange={(e) => setSellingProducts(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                {sellingProducts && (
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Special Permits or Licenses (if selling)</FormLabel>
                      <Textarea name="special_permits" />
                    </FormControl>
                  </GridItem>
                )}
              </Grid>

              <Divider my={6} />

              {/* Logistics and Setup */}
              <Heading as="h3" size="lg" mb={4}>
                Logistics and Setup
              </Heading>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Need assistance with booth setup?</FormLabel>
                    <Checkbox
                      name="assistance_with_setup"
                      value="true"
                      isChecked={assistanceWithSetup}
                      onChange={(e) => setAssistanceWithSetup(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Require additional furniture?</FormLabel>
                    <Checkbox
                      name="additional_furniture"
                      value="true"
                      isChecked={additionalFurniture}
                      onChange={(e) => setAdditionalFurniture(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                {additionalFurniture && (
                  <GridItem colSpan={2}>
                    <FormControl isRequired>
                      <FormLabel>Additional Furniture Details</FormLabel>
                      <Textarea name="additional_furniture_details" />
                    </FormControl>
                  </GridItem>
                )}
              </Grid>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Have large displays or equipment?</FormLabel>
                    <Checkbox
                      name="large_displays"
                      value="true"
                      isChecked={largeDisplays}
                      onChange={(e) => setLargeDisplays(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                {largeDisplays && (
                  <GridItem colSpan={[2, 1]}>
                    <FormControl isRequired>
                      <FormLabel>Large Displays Details</FormLabel>
                      <Textarea name="large_displays_details" />
                    </FormControl>
                  </GridItem>
                )}
              </Grid>

              <Divider my={6} />

              {/* Marketing and Promotion */}
              <Heading as="h3" size="lg" mb={4}>
                Marketing and Promotion
              </Heading>
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Interested in promotional opportunities (sponsorship/advertising)?</FormLabel>
                    <Checkbox
                      name="promotional_opportunities"
                      value="true"
                      isChecked={promotionalOpportunities}
                      onChange={(e) => setPromotionalOpportunities(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={[2, 1]}>
                  <FormControl>
                    <FormLabel>Provide promotional materials for attendee bags?</FormLabel>
                    <Checkbox
                      name="attendee_bag_materials"
                      value="true"
                      isChecked={attendeeBagMaterials}
                      onChange={(e) => setAttendeeBagMaterials(e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
              </Grid>

              <Divider my={6} />

              {/* Additional Information */}
              <Heading as="h3" size="lg" mb={4}>
                Additional Information
              </Heading>
              <FormControl>
                <FormLabel>Specific Requirements or Requests</FormLabel>
                <Textarea name="specific_requirements" />
              </FormControl>
              <FormControl>
                <FormLabel>Additional Comments or Questions</FormLabel>
                <Textarea name="additional_comments" />
              </FormControl>

              <Divider my={6} />

              {/* Agreement */}
              <Heading as="h3" size="lg" mb={4}>
                Agreement
              </Heading>
              <FormControl isRequired>
                <Checkbox
                  name="agreement"
                  value="true"
                  isChecked={agreement}
                  onChange={(e) => setAgreement(e.target.checked)}
                >
                  I have read and agree to the terms and conditions of the expo participation. <span style={{color: '#EC8884'}}>*</span>
                </Checkbox>
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Signature</FormLabel>
                <Input name="signature" />
              </FormControl>
              <FormControl isRequired>
                <FormLabel>Date</FormLabel>
                <Input name="date" type="date" />
              </FormControl>

              <Button type="submit" colorScheme="blue" size="lg">
                Submit
              </Button>
            </Stack>
          </Box>
        </VStack>
      </Container>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} py={4} mt={8}>
        <Container maxW="container.lg" textAlign="center">
          <Text>&copy; 2024 Expo Vendor Registration. All rights reserved.</Text>
        </Container>
      </Box>
    </Box>
  );
}

export default FormPage;
