import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import FormPage from './FormPage';
import ThankYou from './ThankYou';

function App() {
  return (
    <ChakraProvider>
      <Router>
        <Routes>
          <Route path="/" element={<FormPage />} />
          <Route path="/thank-you" element={<ThankYou />} />
        </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
